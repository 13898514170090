.breadcrumb {
    background: transparent;
    align-items: center;
    margin: 0.2rem;
    padding: 0;
    h1 {
        font-size: 1.1rem;
        line-height: 1;
        padding-right: .5rem;
        margin: 0;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            position: relative;
            padding: 0 .5rem;
            line-height: 1;
            vertical-align: bottom;
            color: $gray-600;
            &:after {
                position: absolute;
                top: -1px;
                right: 0;
                content: "";
                height: 16px;
                width: 1px;
                background: $gray-600;
                border-radius: 5px;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            a {
                color: $heading;
            }
        }
    }
}

[dir="rtl"] {
    .breadcrumb {
        h1 {
            padding-right: 0;
            padding-left: .5rem;
        }
    }
}
