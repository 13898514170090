
.create_event_wrap p input {
  margin: 0;
  vertical-align: middle
}

.fc-event {
  position: relative;
  display: block;
  font-size: .85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 0 solid #639!important
}

a.fc-day-grid-event {
  background: #639;
  padding: 5px
}

th.fc-day-header {
  border-bottom-width: 2px;
  padding: 10px 0;
  display: table-cell;
  background: #f5f5f5;
  font-size: 16px;
  font-weight: 700;
  text-align: center
}

td.fc-head-container {
  padding: 0!important
}

.fc-toolbar h2 {
  margin: 0;
  font-weight: 700
}

span.fa {
  font-family: iconsmind!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased
}

span.fa.fa-chevron-left:before {
  content: "\F077"
}

span.fa.fa-chevron-right:before {
  content: "\F07D"
}

.main-header {
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100
}

.main-header .menu-toggle {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 12px
}

.main-header .menu-toggle div {
  width: 24px;
  height: 1px;
  background: #47404f;
  margin: 3px 0
}

.main-header .search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 20px;
  position: relative;
  width: 230px;
  height: 40px
}

.main-header .search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: .8rem;
  line-height: 2;
  height: 100%;
  outline: initial!important;
  padding: .5rem 1rem;
  width: calc(100% - 32px)
}

.main-header .search-bar .search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block
}

.main-header .logo {
  width: 120px
}

.main-header .logo img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block
}

.main-header .show .header-icon {
  background: #f8f9fa
}

.main-header .header-icon {
  font-size: 19px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  margin: 0 2px
}

.main-header .header-icon:hover {
  background: #f8f9fa
}

.main-header .header-icon.dropdown-toggle:after {
  display: none
}

.main-header .header-part-right {
  display: flex;
  align-items: center
}

.main-header .header-part-right .user {
  margin-right: 2rem
}

.main-header .header-part-right .user img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer
}

.main-header .notification-dropdown {
  padding: 0;
  max-height: 260px;
  cursor: pointer
}

.main-header .notification-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  border-bottom: 1px solid #dee2e6
}

.main-header .notification-dropdown .dropdown-item .notification-icon {
  background: #eee;
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center
}

.main-header .notification-dropdown .dropdown-item .notification-icon i {
  font-size: 18px
}

.main-header .notification-dropdown .dropdown-item .notification-details {
  padding: .25rem .75rem
}

.main-header .notification-dropdown .dropdown-item:active {
  color: inherit;
  background: inherit
}

@media (max-width: 991px) {
  .main-header .search-bar {
    width:180px
  }

  .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px
  }
}

@media (max-width: 615px) {
  .main-header .header-part-right .user {
    margin-right:0
  }
}

@media (max-width: 580px) {
  .main-header {
    height:70px;
    padding: 0 1.5rem
  }

  .main-header .logo {
    width: 60px
  }

  .main-header .search-bar {
    display: none
  }

  .main-header .menu-toggle {
    width: 60px;
    margin:0;
  }

  .main-header .header-part-right .user {
    margin-right: 0;
    padding-right: 0
  }

  .notification-dropdown {
    left: 10px!important
  }
}

@media (max-width: 360px) {
  .main-header .menu-toggle {
    margin:0
  }
}
